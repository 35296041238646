$(document).ready(function () {

   // Init Functions
   mainMenu();
   tabMenu();
   simpleBanner();
   searchForm();
   owlSlider();
   simpleAccordion();
   scrollToPos();
   // scrollToFixedHeader();
   scrollHeader();
   removeImgAttr();
   playVideo();

});

// Functions

function mainMenu() {

   $('body').on('click', '.menu-mobile', function () {
      $('.menu-main-container').slideToggle(300);
      $(this).addClass('menu-mobile-open');
      $('.menu-main-container').removeClass('menu-main-container-open');
   });

   $('body').on('click', '.menu-mobile-open', function () {
      $(this).removeClass('menu-mobile-open');
      $('.menu-main-container').addClass('menu-main-container-open');
   });

   $('.menu-item-has-children > a').on('click', function () {
      $(this).parent().siblings().find('ul').slideUp(200);
      $(this).next('ul').stop(true, false, true).slideToggle(200);
      return false;
   });

}

function tabMenu() {

   $('.m-tabs-nav-item').on('click', function () {
      var sectionID = $(this).closest('section').attr('id');
      var tabName = $(this).attr('class').substring(31);
      $('#' + sectionID + ' .m-tabs-nav-item').removeClass('m-tabs-nav-current-item');
      $(this).addClass('m-tabs-nav-current-item');
      $('#' + sectionID + ' .m-tabs-tab').fadeOut(0);
      $('#' + sectionID + ' .m-tabs-tab' + tabName).fadeIn(800);
      console.log(tabName);
   });
}

function simpleBanner() {
   $('.close').on('click', function (e) {
      $(e.target).closest('.to-close').slideToggle(200);
   });
}

function searchForm() {
   $('body').on('click', '.js-menu-search', function () {
      $('.js-search-content').slideToggle(200);
      $('.site-main, .site-footer').toggleClass('disabled', 400);
   });
}

function owlSlider() {

   $('.m-teaser').closest('section').addClass('overflow-hidden');
   $('.m-slider').closest('section').addClass('overflow-hidden');
   $('.m-image-overflow').closest('section').addClass('overflow-hidden');

   $('.m-slider-1').owlCarousel({
      loop: false,
      margin: 40,
      responsiveClass: true,
      stagePadding: 0,
      dots: true,
      nav: true,
      navText: [
         "←",
         "→"
      ],
      responsive: {
         0: {
            items: 1,
            margin: 16,
            nav: true
         },
         640: {
            items: 1,
            margin: 24,
            nav: true
         },
         1024: {
            items: 1,
            margin: 32,
            nav: true
         },
         1280: {
            items: 1,
            margin: 40,
            nav: true
         }
      }
   });

   $('.m-slider-2').owlCarousel({
      loop: false,
      margin: 40,
      responsiveClass: true,
      stagePadding: 0,
      dots: true,
      nav: true,
      navText: [
         "←",
         "→"
      ],
      responsive: {
         0: {
            items: 1,
            margin: 16,
            nav: true
         },
         640: {
            items: 2,
            margin: 24,
            nav: true
         },
         1024: {
            items: 2,
            margin: 32,
            nav: true
         },
         1280: {
            items: 2,
            margin: 40,
            nav: true
         }
      }
   });

   $('.m-slider-3').owlCarousel({
      loop: false,
      margin: 40,
      responsiveClass: true,
      stagePadding: 0,
      dots: true,
      nav: true,
      navText: [
         "←",
         "→"
      ],
      responsive: {
         0: {
            items: 1,
            margin: 16,
            nav: true
         },
         640: {
            items: 2,
            margin: 24,
            nav: true
         },
         1024: {
            items: 3,
            margin: 32,
            nav: true
         },
         1280: {
            items: 3,
            margin: 40,
            nav: true
         }
      }
   });

   $('.m-slider-4').owlCarousel({
      loop: false,
      margin: 40,
      responsiveClass: true,
      stagePadding: 0,
      dots: true,
      nav: true,
      navText: [
         "←",
         "→"
      ],
      responsive: {
         0: {
            items: 1,
            margin: 16,
            nav: true
         },
         640: {
            items: 2,
            margin: 24,
            nav: true
         },
         1024: {
            items: 4,
            margin: 32,
            nav: true
         },
         1280: {
            items: 4,
            margin: 40,
            nav: true
         }
      }
   });

   $('.m-slider-5').owlCarousel({
      loop: false,
      margin: 40,
      responsiveClass: true,
      stagePadding: 0,
      dots: true,
      nav: true,
      navText: [
         "←",
         "→"
      ],
      responsive: {
         0: {
            items: 1,
            margin: 16,
            nav: true
         },
         640: {
            items: 2,
            margin: 24,
            nav: true
         },
         1024: {
            items: 5,
            margin: 32,
            nav: true
         },
         1280: {
            items: 5,
            margin: 40,
            nav: true
         }
      }
   });
}

function simpleAccordion() {

   $('.m-accordion-header').on('click', function (e) {

      //Close all <div> but the <div> right after the clicked <a>
      // $(e.target).next('div').siblings('div').hide();


      $(e.target).next('div').slideToggle(200);

      //Toggle header status
      // $(e.target).siblings('h3').removeClass('accordion-header-active');
      $(this).toggleClass('m-accordion-header-active');

   });

}

function scrollToPos() {

   $('a[href^="#"]').on('click', function(event) {
      var target = $(this.getAttribute('href'));

      if( target.length ) {
          event.preventDefault();
          $('html, body').stop().animate({
              scrollTop: target.offset().top
          }, 1000);
      }
  });

}

function scrollToFixedHeader() {

   $('a[href^="#"]').on('click', function (event) {
      var target = $($(this).attr('href'));
      var fixedHeader = $('.header-main');
      if (target.length) {
         event.preventDefault();
         $('html, body').animate({
            scrollTop: target.offset().top - fixedHeader.outerHeight()
         }, 400);
      }
   });

}

function scrollHeader() {

   var lastScrollTop = 0;
   var header = $('.site-header');

   $(window).scroll(function () {
      var scrollTop = $(this).scrollTop();

      if (scrollTop < lastScrollTop) {
         // Scrolling up
         header.css('top', '0');
      } else {
         // Scrolling down
         header.css('top', '-120px'); // Adjust the value based on your header height
      }
      lastScrollTop = scrollTop;
   });
}

function removeImgAttr() {

   $('img').each(function () {
      $(this).removeAttr('width');
      $(this).removeAttr('height');
   });

}

function playVideo() {

   $('.play-video').on('click', function () {
      var videoId = $(this).attr('class').substring(47);
      // console.log(videoId);

      $('.m-video-container').each(function () {
         var iframe = $('.m-video-container-' + videoId).find('iframe');
         iframe.attr('src', iframe.data('src'));
      });

      $(this).closest('.m-video-capture').fadeOut(200);

   });
}